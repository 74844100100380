// CarList.js
import c from "./styles.module.scss";
import React from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import Card from "../card";
import avtobus from "../../images/avtobus.jpg";

import assenizator from "../../images/assenizator.jpg";
import bortvoykuzon from "../../images/bortvoykuozb.jpg";
import samasval from "../../images/samasval.jpg";
import saxar from "../../images/Dsaxar.jpg";
import fvr from "../../images/fvr34.jpg";
import hc45 from "../../images/HC45.jpg";
import irbis from "../../images/irbis.jpg";
import metallzakrit from "../../images/new.JPG";
import npr755 from "../../images/npr755.jpg";
import npr82 from "../../images/avtofurgon.jpg";
import oksus from "../../images/oksus.jpg";
import samasval2 from "../../images/NPR90Lsamasval.jpg";
import Sazhd from "../../images/Sazhd50.jpg";
import oksus2new from "../../images/oksus2new.JPG";
import irbis2new from "../../images/irbis2new.JPG";
const CarList = () => {
  const { t } = useTranslation();
  const cars = [
    {
      name: `${t("Npr821")}`,
      price: "637 000 000,00",
      img: npr82,
    },
    {
      name: `${t("Npr822")}`,
      price: "636 000 000,00",
      img: npr755,
    },
    {
      name: `${t("Npr823")}`,
      price: "631 000 000,00",
      img: bortvoykuzon,
    },
    {
      name: `${t("Npr_75k1")}`,
      price: "543 000 000,00",
      img: npr82,
    },
    {
      name: `${t("Npr_75k2")}`,
      price: "541 000 000,00",
      img: npr755,
    },
    {
      name: `${t("Npr_75k3")}`,
      price: "536 000 000,00",
      img: bortvoykuzon,
    },
    { name: `${t("Fvr34Q1")}`, price: "825 000 000,00", img: metallzakrit },

    {
      name: `${t("Fvr34Q2")}`,
      price: "874 000 000,00/899 000 000,00 Comfort",
      img: fvr,
    },
    {
      name: `${t("Fvr34K")}`,
      price: "895 000 000,00",
      img: samasval,
    },
    {
      name: `${t("Nqr90L1")}`,
      price: "595 000 000,00",
      img: npr82,
    },
    {
      name: `${t("Nqr90L2")}`,
      price: "592 000 000,00",
      img: npr755,
    },
    {
      name: `${t("Nqr90L3")}`,
      price: "587 000 000,00",
      img: bortvoykuzon,
    },
    {
      name: `${t("Nmr77H")}`,
      price: "452 000 000,00",
      img: npr82,
    },
    {
      name: `${t("Nqrl90L")}`,
      price: "649 000 000,00",
      img: samasval2,
    },
    {
      name: `${t("Npr82L")}`,
      price: "699 000 000,00",
      img: samasval2,
    },
    {
      name: `${t("SazLe60")}`,
      price: "999 900 000,00",
      img: avtobus,
    },
    {
      name: `${t("SazHd50")}`,
      price: "839 000 000,00",
      img: Sazhd,
    },
    {
      name: `${t("SazHc45")}`,
      price: "631 000 000,00",
      img: hc45,
    },
    {
      name: `${t("Npr82")}`,
      price: "684 000 000,00",
      img: assenizator,
    },
    {
      name: `${t("Sahar")}`,
      price: "430 000 000,00",
      img: saxar,
    },
    {
      name: `${t("Oksus")}`,
      price: "550 000 000,00",
      img: oksus2new,
    },
    {
      name: `${t("Irbis")}`,
      price: "590 000 000,00",
      img: irbis2new,
    },
    {
      name: `${t("Oksus2")}`,
      price: "510 000 000,00",
      img: oksus2new,
    },
    {
      name: `${t("Irbis2")}`,
      price: "560 000 000,00",
      img: irbis2new,
    },
  ];

  return (
    <div id="categories" className={c.all}>
      <h2 className={c.title}>{t("catalogue")}</h2>

      <div className={c.carList}>
        {cars.map((car, index) => (
          <Card
            key={index}
            carName={car.name}
            carPrice={car.price}
            carImg={car.img}
          />
        ))}
      </div>
    </div>
  );
};

export default CarList;
