import React, { Children } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Footer2 from '../footer2'
function Layout({children}) {
  return (
    <div>
      <Navbar/>
      {children}
      <Footer/>
<Footer2/>
    </div>
  )
}

export default Layout
