import React from "react";
import styles from "./styles.module.scss";
import { useTranslation, initReactI18next } from 'react-i18next';
const Card = ({ carName, carPrice, carImg }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.card}>
      <img src={carImg} alt={carName} className={styles.cardImg} />
      <div className={styles.cardContent}>
      <p className={styles.cardPrice}>{carPrice}  {t('money')}</p>
        <h2 className={styles.cardTitle}>{carName}</h2>
      
      </div>
    </div>
  );
};

export default Card;
