import React from 'react';
import c from './styles.module.scss';
import { useTranslation, initReactI18next } from 'react-i18next';

import about from "../../images/about-2.jpg"
import aboutt from "../../images/aboutt.jpg"

const About = () => {
    const { t } = useTranslation();
    return (
      <div id='about' className={c.all}> 
       <h1 className={c.about__title}>{t('about')}</h1>
      
        <div className={c.cover}>
            
            <div className={c.image}>
                <img src={about} alt="isuzu photo" /> 
            </div>
            <div className={c.about}>
            
                <div className={c.about__content}>
                    <p className={c.about__text}>
                        {t('aboutUs')}
                    </p>
                </div>
            </div>
        </div>
       
        <div className={c.cover}>
    
            
            <div className={c.about}>
            
                <div className={c.about__content}>
                    <p className={c.about__text}>

                        {t('aboutUs2')}<br/>
                        {t('diagnostics')}<br/>
                        {t('engine_repair')}<br/>
                        {t('transmission_repair')}<br/>
                        {t('air_system_repair')}<br/>
                        {/* {t('suspension_repair')}<br/> */}
                        {t('electrical_repair')}<br/>
                        {t('hydraulic_system_repair')}<br/>
                        {t('body_works')}<br/>
                        {t('final')}
                       
                        
                    </p>
                </div>
            </div>
            <div className={c.image}>
                <img src={aboutt} alt="isuzu photo" /> 
            </div>
        </div>
      </div>
      
    );
};

export default About;
