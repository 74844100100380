import React from 'react'
import { useTranslation, initReactI18next } from 'react-i18next';
import c from "./styles.module.scss"
function Footer2() {
  const { t } = useTranslation();
  return (
    <div>
      <p className={c.footer}>©<span>{t('footer')}</span>
</p>
    </div>
  )
}

export default Footer2;